import estados from "../Estados";

const ButtonUnknow = () => {
    return (
        <button className="btn btn-warning" disabled={true}>
            No Encontrado
        </button>
    )
}

export function ButtonDenunciasSegunEstado({ estado }) {
    const estadoStr = String(estado);
    const statusType = estados.find((e) => e.value === estadoStr);
    
    if (statusType === undefined) {
        return <ButtonUnknow />;
    }

    return (
        <button className={`btn btn-${statusType.color}`} disabled={true}>
            {statusType.label}
        </button>
    )
}
