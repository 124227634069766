import React from "react";
import Menu from "../../../Common/Menu"
import Nav from "../Nav"
import ReportIcon from '@mui/icons-material/Report';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { botonSegunEstado, FormatoHora } from "../../../Common/Funciones";
import moment from 'moment-timezone';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { DenunciasCard } from "./components/DenunciasCard";
import { DenunciasReport } from "../Reportes/DenunciasReport";
import { getApiUrl } from "../../../Common/api";

class Denuncias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ListaDenuncias: [],
            ListTiposDenuncia: []
        };
    }
    
    CargarTiposDenuncia() {
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/typecomplaintadm/", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    ListTiposDenuncia: result,
                })
            })
            .catch(error => console.log('error', error));
    }

    CargarDenuncias() {

        const timezone = 'America/Lima';
        const today = moment().tz(timezone).format('YYYY-MM-DD');

        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/complaintperson/?start_date=" + today + "&end_date=" + today, requestOptions)
            .then(response => response.json())
            .then(result => {
                const filteredResult = result.filter(item => item.lat !== 'null' && item.lng !== 'null' && item.lat !== null && item.lng !== null);
                this.setState({
                    ListaDenuncias: filteredResult,
                })
            })
            .catch(error => console.log('error', error));
    }

    FiltrarDni(dni) {
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/complaintperson/?person_id__dni=" + dni, requestOptions)
            .then(response => response.json())
            .then(result => {
                const filteredResult = result.filter(item => item.lat !== 'null' && item.lng !== 'null' && item.lat !== null && item.lng !== null);
                this.setState({
                    ListaDenuncias: filteredResult,
                })
            })
            .catch(error => console.log('error', error));
    }

    FiltrarTipo(tipo) {
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/complaintperson/?type=" + tipo, requestOptions)
            .then(response => response.json())
            .then(result => {
                const filteredResult = result.filter(item => item.lat !== 'null' && item.lng !== 'null' && item.lat !== null && item.lng !== null);
                this.setState({
                    ListaDenuncias: filteredResult,
                })
            })
            .catch(error => console.log('error', error));
    }

    componentDidMount() {
        this.CargarDenuncias();
        this.CargarTiposDenuncia();
    }

    render() {
        const { ListaDenuncias } = this.state;
        const { ListTiposDenuncia } = this.state;
        return (
            <div id="Denuncias">
                <Menu />
                <div className="row">
                    <Nav />
                    <div className="col-12 col-md-10 border d-flex flex-column justify-content-center align-items-center">
                        <div className="row my-2">
                            <div className="col-md-12 mb-3">
                                <div className="d-flex align-items-center">
                                    <ReportIcon style={{ fontSize: "2rem" }} />
                                    <h1 className="ml-2 mb-0" style={{ fontSize: "2rem" }}>Denuncias</h1>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="d-flex justify-content-center align-items-center ">
                                <div className="cursor-pointer bg-light rounded">
                                    <Link className="Link d-flex align-items-center text-black p-2" to={`/Alertas/Denuncias/TipoDenuncias`}>
                                        <FormatListBulletedIcon />
                                        <h5 className="mb-0 ms-2" >TIPOS</h5>
                                    </Link>
                                </div>
                                <div className="ml-5">
                                    <DenunciasReport />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-group mb-3">
                                    <input id="search-input" type="text" className="form-control" placeholder="Buscar por dni..." />
                                    <div onClick={() => this.FiltrarDni(document.getElementById('search-input').value)} className="input-group-append pointer">
                                        <span className="input-group-text"><SearchIcon /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group mb-3">
                                    <select className="form-control" id="tipo-denuncia" name="tipo-denuncia" defaultValue="Tipo de denuncia">
                                        {ListTiposDenuncia.map((List) => (
                                            <option key={List.id} value={List.id}>{List.name}</option>
                                        ))}
                                    </select>
                                    <div onClick={() => this.FiltrarTipo(document.getElementById('tipo-denuncia').value)} className="input-group-append">
                                        <span className="input-group-text"><SearchIcon /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-md-2 ">
                            {ListaDenuncias.map((List) => (
                                <DenunciasCard key={List.id} denuncia={List} />
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Denuncias;