import React from "react";
import Menu from "../../Common/Menu";
import Nav from "./Nav";
import { getApiUrl } from "../../Common/api";
import estados from "../../Common/Estados";
import L from "leaflet";
import { botonSegunEstado, FormatoHora } from "../../Common/Funciones";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { w3cwebsocket as WebSocket } from "websocket";
import forge from "node-forge";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Swal from "sweetalert2";
import { getAllDevices, getSocketConnection } from "../../services/services";
import { LocalAlert } from "./components/LocalAlert";
import { DeviceMarkers } from "./components/DeviceMarkers";
import { AlertNoGps } from "./components/AlertNoGps";
import { getDateFromHistory } from "../../Common/utils";
import { CompleteName } from "../../Common/componentes/CompleteName";
import { getConnectionBussines } from "../../Common/utils/storageUtils";

class Mapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MarkersTraccar: [],
      Markers_ws: [],
      sosLocalAlerts: [],
      description: "",
      isFullscreen: false,
      code_sos: 20,
      datos: [],
      center: this.getCenterMap()
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleToggleFullscreen = this.handleToggleFullscreen.bind(this);
    this.handleResetFullscreen = this.handleResetFullscreen.bind(this);
    this.audioAlerta = new Audio("/mp3/alert.mp3");
    this.reproducirSonidoDeAlerta = this.reproducirSonidoDeAlerta.bind(this);
    this.detenerSonidoDeAlerta = this.detenerSonidoDeAlerta.bind(this);
    this.activarAlertaSos = this.activarAlertaSos.bind(this);
    this.cambiarEstado = this.CamabiarEstado.bind(this)
    // Management Alerts
    this.alertQueue = [];
    this.alertActive = false;
  }
  ws = new WebSocket("ws://159.65.107.11:60001/?App=Mi-Muni");
  // wsTrackar = new WebSocket("ws://159.65.107.11:6959/?App=Onta");
  wsTrackar = getSocketConnection();

  handleToggleFullscreen() {
    this.Fullscreen();
  }

  handleResetFullscreen() {
    this.setState({
      isFullscreen: null,
    });
  }
  Fullscreen() {
    const element = document.getElementById("mapFull");
    if (!this.state.isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      this.setState({
        isFullscreen: null,
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  CargarMarcadores() {
    var access_token = localStorage.getItem("access_token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);
    myHeaders.append("Content-Type", "application/json");
    const api = getApiUrl();
    fetch(api + "alert/personsos/?status=0", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        const filteredResult = result.filter(
          (item) =>
            item.lat !== "null" &&
            item.lng !== "null" &&
            item.lat !== null &&
            item.lng !== null
        );
        this.setState({
          Markers: filteredResult,
        });
      })
      .catch((error) => console.log("error", error));
  }

  CamabiarEstado(id, value) {
    var access_token = localStorage.getItem("access_token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + access_token);
    var raw = JSON.stringify({
      status: value,
      description: this.state.description,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const api = getApiUrl();
    fetch(api + "alert/historypersonsos/" + id + "/", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    this.CargarMarcadores();
  }
  async WebSocketTrackar() {
    const bussinesData = JSON.parse(getConnectionBussines());
    const mailTraccar = bussinesData?.data?.mail_traccar;
    const devices = await getAllDevices(mailTraccar);
    this.wsTrackar.onmessage = (evt) => {
      let jsonData = JSON.parse(evt.data);

      if (jsonData.positions) {
        const newDevicesPosictions = devices.map((device) => {
          const devicePosition = jsonData.positions.find((position) => position.deviceId === device.id);
          if (devicePosition) {
            return {
              ...device,
              latitude: devicePosition.latitude,
              longitude: devicePosition.longitude,
              online_date: devicePosition.devicetime,
              speed: devicePosition.speed,
            };
          }
          return device;
        })
        this.setState({
          MarkersTraccar: newDevicesPosictions,
        });
      }
    }
    // this.wsTrackar.onopen = () => {
    //   let datos = this.state.datos;
    //   let jsonObject = {
    //     event: "USER_RELOGGIN",
    //     payload: { token: datos.mail_traccar },
    //     app: "Onta",
    //     token: "f67s987df6sa98f76s9d7f6safsfs76fsfshfbsakjfh",
    //   };
    //   console.log("------------- conectando traccar ----------------");
    //   console.log(jsonObject);
    //   this.wsTrackar.send(JSON.stringify(jsonObject));
    // };
    // this.wsTrackar.onmessage = (evt) => {
    //   console.log(evt);
    //   let eventData = JSON.parse(evt.data);
    //   let eventName = eventData.event;
    //   if (eventName === "USER_RELOGGIN") {
    //     let id_tracker = eventData.data?.s.id;
    //     let jsonObject2 = {
    //       event: "USER_ACCESS",
    //       payload: { session: "M1", user: id_tracker },
    //       app: "Onta",
    //       token: "f67s987df6sa98f76s9d7f6safsfs76fsfshfbsakjfh",
    //     };
    //     console.log(jsonObject2);
    //     this.wsTrackar.send(JSON.stringify(jsonObject2));
    //   }else if(eventName === "USER_ACCESS"){
    //     console.log(eventData.data.t);//data t vehiculos
    //     this.setState({
    //       MarkersTraccar: eventData.data.t,
    //     });
    //   }else if(eventName === "COORDS_UPDATE"){
    //     var coordenadas = eventData.data;
    //     console.log(coordenadas);
    //     this.updateMarkerTraccar(coordenadas.deviceid, coordenadas.latitude, coordenadas.longitude, coordenadas.devicetime);
    //   }
    // };
    // this.wsTrackar.onclose = () => {};
    // this.wsTrackar.on("connection", (socket) => {
    //  console.log("conectado a traccar");
    //  console.log(socket)
    // })

    // this.wsTrackar.on("message_to_frontend", (data) => {
    //   console.log(data);
    // })
  }

  getCenterMap() {
    const bussinesData = JSON.parse(getConnectionBussines());
    let latI = 0
    let lngI = 0
    try {
      latI = parseFloat(bussinesData.data.lat_map)
      lngI = parseFloat(bussinesData.data.lng_map)
    } catch (error) {
      console.log(error)
      //huanuco
      latI = -9.930648 
      lngI = -76.241496 
    }
      return {lat:  latI, lng: lngI}
  }

  WebSocket() {
    this.ws.onopen = () => {
      //console.log("Se abrio el ws de SoS");
    };

    this.ws.onmessage = (evt) => {
      var data = evt.data;
      let parsedData;
      try {
        parsedData = JSON.parse(data);
        if (parsedData.connetion === "ok") {
          var jsonObject = {
            tipe: "USER_LOGGIN",
            user: "71061080",
            tipeloggin: 0,
          };

          const pemKey = `
                    -----BEGIN PUBLIC KEY-----
                    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuLBKxRnBxdjXABZW54Yz3jZ10Tw9htvg7Dlg0QT555UhNr0J1nm9i0JILHjC91+nA1+ueeJj54e96OB0TIp3Z7wfPrrsWwPW3upWcXS+3wiN9hlmu4y3qyIqwvdMG7DglQLzCO1GcsL5rCX2Oxi71jMMWo2BC8ZeCUQ7lpAuIr+KFPHkS1DlVky/HLWk0xpumrLsKya2d6hYclUb2E9kuuhi1nWslBThFbRKHE8PnzV01mO47UGMl5fvTziA3dgiqtUvu7UZK0uFMg7FbiyS9XmNsFL3OurXj+3l213Y8KiBRdLQ9lWfnzgapgSyv1UyGGSDgMMTQo/urX4B1UlHbQIDAQAB
                    -----END PUBLIC KEY-----`;

          const publicKey = forge.pki.publicKeyFromPem(pemKey);

          const jsonString = JSON.stringify(jsonObject);

          const encrypted = forge.util.encode64(
            publicKey.encrypt(forge.util.encodeUtf8(jsonString))
          );

          this.ws.send(
            JSON.stringify({
              mm: this.state.code_sos,
              payload: encrypted,
            })
          );
        } else if (parsedData.data) {
          const encryptedData = parsedData.data;
          const encodedPrivateKey = `
                    -----BEGIN RSA PRIVATE KEY-----
                    MIIEpAIBAAKCAQEAuLBKxRnBxdjXABZW54Yz3jZ10Tw9htvg7Dlg0QT555UhNr0J
                    1nm9i0JILHjC91+nA1+ueeJj54e96OB0TIp3Z7wfPrrsWwPW3upWcXS+3wiN9hlm
                    u4y3qyIqwvdMG7DglQLzCO1GcsL5rCX2Oxi71jMMWo2BC8ZeCUQ7lpAuIr+KFPHk
                    S1DlVky/HLWk0xpumrLsKya2d6hYclUb2E9kuuhi1nWslBThFbRKHE8PnzV01mO4
                    7UGMl5fvTziA3dgiqtUvu7UZK0uFMg7FbiyS9XmNsFL3OurXj+3l213Y8KiBRdLQ
                    9lWfnzgapgSyv1UyGGSDgMMTQo/urX4B1UlHbQIDAQABAoIBAHG/wo1aq8nbH7Bi
                    EexO76sxB6dy3sIssn9LZ6HU2sYTR32bOG/sUx1uGV2qkwog05B0YdWX5cg4BnNj
                    qr1pmtk9OFE+d0wnNTPeSJdgPB7J+gVU570jkgvI42VpQmrbqbadNmqNGRKdm4C3
                    cMw9E5N9pWIwPQavikNSzxCQhJnJKZtgJDTtsVZfeSLmbGZONLUyDa3puQIXyDt2
                    TDZi71+Epq93AIE38SElKXxkcLD9BE71Rhf8YFSUsLZPrywwpp7eH6kZI1hoagMF
                    AJN89mt9xqJhxMJD4vkgLKweW4F1907dJi+rdYH7AM5QnT807ihBUy/ovECPLfzu
                    kYXsWlECgYEA7mc/PfXDgDDd5CQvJnXwTOshOuAw6LRD8TAS4OCXEwvNs8U1ptri
                    xw9MDCyqJIcYdBdBB+wd3BrT8KA0f/ZQadI7H/0KdL8vO8bHCXcMr3rc0XNkyVnM
                    iYl/3RvNRUu1RHfO1ZL9Vxko99mQGY1Hg0MtnzCm6MGPiFiEt61bLfcCgYEAxlIU
                    K97dCjKLMh/GMzDHBUazcM3+3g0pIFTdFeyjm1Cnnu6PTRXdyP01gyiccCvw7bHO
                    gouqJNL9JLFl18gmT+WjBrFkuNwq4lKNMpAduFk5p8Oc02mtJotYGpS48QEBY3zH
                    2bfQTBdySUZ41n0OYnbYMT/szQKqtkT2W01p7LsCgYEAwH7jZK8tZTOP5ueEnsR5
                    /FGaqkRdmXf8qCGODL+rnHhaZr5k3M4+cEacu/PQid//UbV5D+GKFrAe01s4KN6E
                    /Xz/uouBxtScWeLd0Xmr54EIDaLkDWSRr7gzYyBQtZQ00A0BGBgwsQQivUWRgeA+
                    jvFORAxvqN6pe4kl2H2Ecu8CgYBYc9dHUdkoaS6oJn/R6Rl7AvOZhmb8RsZnAzKw
                    SqEV7r00J5aGfRA0SqsJWp2bch29BibjFsPGNMxvBygzO7PKwtMZcxlHUftWghcz
                    zA9VX4ZViutxZ5qwrEmZYWezJg2mf8rrEg63QWXZxLK0h4ZY6ES4gphtP5lAPjXa
                    twZdbQKBgQCQhchpOhp8s+zVLkkakkQaYdlre3KJhjEsH17nB4ZbkoEBhQwYGFdx
                    wjZonWcNrgc+NYl7RQzBU8IVit0R7i5eXPosroMLWo79SNc3Ka0wXllQlxG3o9/w
                    0qPYLJKAUFm0r0mc4o7dXZxuO430QMDia/+uA3Z8sSA6zREcVZZY+w==
                    -----END RSA PRIVATE KEY-----`;
          const privateKey = forge.pki.privateKeyFromPem(encodedPrivateKey);
          const decryptedString = forge.util.decodeUtf8(encryptedData);
          const decodeprivateKey = forge.util.decode64(decryptedString);

          var result = "";
          for (var i = 1; i <= decodeprivateKey.length / 256; i++) {
            var j = i * 256;
            var k = (i - 1) * 256;
            const truncatedPrivateKey = decodeprivateKey.slice(k, j);
            result = result + privateKey.decrypt(truncatedPrivateKey);
          }
          const decryptedJson = JSON.parse(result);
          if (decryptedJson.event === "USER_LOGGIN") {
            //console.log(decryptedJson)
            const objetos = decryptedJson.msg.u;
            const objetosSOS = decryptedJson.msg.j;
            //console.log("LOGIN ojetos: ", objetos)
            //console.log("LOGIN ojetos SOS: ", objetosSOS)
            this.handleDateMarkerHistory(objetos)
            this.setState({
              Markers_ws: objetos,
              sosLocalAlerts: objetosSOS,
            });
          } else if (decryptedJson.type === "SOS_STATUS_UPDATE") {
            //console.log("update: ", decryptedJson)
            const objetos = decryptedJson.u;
            this.handleDateMarkerHistory(objetos)
            this.encontrarElementosNuevos(this.state.Markers_ws, objetos);
            this.setState({
              Markers_ws: objetos,
            });

          } else if (decryptedJson.type === "LOCAL_LIST_SOS_STATUS_UPDATE") {
            //console.log("SOS LOCAL: ", decryptedJson)
            const objetosSOS = decryptedJson.u;
            //console.log("SOS LOCAL objetos:", objetosSOS)
            // check new alerts
            const nuevosObjetos = objetosSOS.filter(newSOS => {
              return !this.state.sosLocalAlerts.some(oldSOS => oldSOS.id === newSOS.id);
            });
            if (nuevosObjetos.length > 0) {
              this.detenerSonidoDeAlerta();
              let msgAlerta = "Mensaje de Alerta";
              this.addAlertToQueueAndProcess(msgAlerta);
            }
            const objetosActualizados = objetosSOS.map(newSOS => {
              const objetoExistente = this.state.sosLocalAlerts.find(oldSOS => oldSOS.id === newSOS.id);
              return objetoExistente ? newSOS : { ...newSOS, isNew: true };
            });
            this.setState({
              sosLocalAlerts: objetosActualizados,
            });

          } else {
            // update cords from sos person, track person, SOS_ALARM type event
            //console.log("person: ", decryptedJson)
            this.updateMarker(
              decryptedJson.person_id_id,
              decryptedJson.lat,
              decryptedJson.lng,
              decryptedJson.date_time_update,
            );
          }
        } else if (parsedData.status) {
          //console.log(parsedData);
        }
      } catch (error) {
        //console.log(error);
      }
    };

    this.ws.onclose = () => {
      Swal.fire({
        title: "SOS Desconectado",
        text: "Se ha perdido la conexión con el SOS.",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      console.log("Se desconecto el ws de SoS");
    };
  }

  handleDateMarkerHistory = async (markerWs) => {
    for (const mws of markerWs) {
      const history_date_created = await getDateFromHistory(mws)
      //console.log(history_date_created)
      mws.alert_date_created = history_date_created
    }
  }

  encontrarElementosNuevos(array1, array2) {
    const nuevosElementos = array2.filter(
      (item2) =>
        !array1.some((item1) => item1.person_id_id === item2.person_id_id)
    );
    const elementosEliminados = array1.filter(
      (item1) =>
        !array2.some((item2) => item2.person_id_id === item1.person_id_id)
    );

    if (nuevosElementos.length > 0) {
      let msgAlerta = "Mensaje de Alerta";
      this.addAlertToQueueAndProcess(msgAlerta);
    }
    // console.log("-------------NUEVOS---------------");
    // console.log(nuevosElementos);
    // console.log("-------------ELIMINADOS---------------");
    // console.log(elementosEliminados);
  }
  updateMarker(markerId, lat, lng, date_time_update) {
    if (!markerId || !lat || !lng || !date_time_update) {
      console.error("Faltan datos de entrada");
      return;
    }

    const { Markers_ws } = this.state;
    const updatedMarkers = Markers_ws.map((marker) =>
      marker.person_id_id === markerId
        ? {
          ...marker,
          lat: lat,
          lng: lng,
          date_time_update_coords: date_time_update,
        }
        : marker
    );

    this.setState({ Markers_ws: updatedMarkers });
  }
  updateMarkerTraccar(markerId, lat, lng, date_time_update) {
    if (!markerId || !lat || !lng || !date_time_update) {
      console.error("Faltan datos de entrada" + markerId + " - " + lat + " " + lng + " - " + date_time_update);
      return;
    }

    const { MarkersTraccar } = this.state;
    const updatedMarkers = MarkersTraccar.map((marker) =>
      marker.id === markerId
        ? {
          ...marker,
          latitude: lat,
          longitude: lng,
          online_date: date_time_update,
        }
        : marker
    );

    this.setState({ MarkersTraccar: updatedMarkers });
  }

  componentDidMount() {
    this.cargarApiConexion();
    this.WebSocket();
    this.WebSocketTrackar();
  }

  cargarApiConexion() {
    var almacenado = getConnectionBussines();
    if (almacenado) {
      var parsedData = JSON.parse(almacenado);
      this.ws = new WebSocket(parsedData?.data?.ip_sos);
      this.state.code_sos = parsedData.data.code_sos;
      this.state.datos = parsedData.data;
    }
  }
  reproducirSonidoDeAlerta() {
    this.audioAlerta.play();
    this.audioAlerta.addEventListener(
      "ended",
      function () {
        this.audioAlerta.currentTime = 0;
        this.audioAlerta.play();
      }.bind(this)
    );
  }

  detenerSonidoDeAlerta() {
    this.audioAlerta.pause();
    this.audioAlerta.currentTime = 0;
  }

  addAlertToQueueAndProcess(sos) {
    this.alertQueue.push(sos);
    this.activarAlertaSos();
  }

  activarAlertaSos() {
    if (this.alertActive || this.alertQueue.length === 0) {
      return;
    }

    this.alertActive = true;
    const sos = this.alertQueue.shift();

    this.reproducirSonidoDeAlerta();

    Swal.fire({
      title: "Alerta SOS !!!",
      text: sos,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        this.detenerSonidoDeAlerta();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Alerta recibida!",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.alertActive = false;
          this.activarAlertaSos();
        });
      }
    });
  }

  render() {
    const { Markers_ws, MarkersTraccar, sosLocalAlerts } = this.state;

    const iconSuccess = L.icon({
      iconUrl: "./img/Icon-danger.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });
    
    return (
      <div id="mapa">
        <Menu />
        <div className="row">
          <Nav />
          <div
            className="col-12 col-md-10 border"
            style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
          >
            <div className="container h-100 d-flex flex-column w-100">
              <div className="flex-fill">
                <div className="h-85 map border my-1" id="mapFull">
                  <MapContainer
                    center={[this.state.center.lat, this.state.center.lng] }
                    zoom={14}
                    scrollWheelZoom={true}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {Markers_ws.map((Datos) => (
                      <div key={Datos.person_id_id}>
                        {Datos.lat !== "null" && Datos.lng !== "null" ? (
                          <Marker
                            position={[Datos.lat, Datos.lng]}
                            icon={iconSuccess}
                          >
                            <Popup>
                              <p className="text-center text-left">
                                {" "}
                                {botonSegunEstado(Datos.status)} Creado:{" "}
                                {Datos.alert_date_created}  
                              </p>
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th className="text-center text-left">
                                      Nombre
                                    </th>
                                    <th className="text-center text-left">
                                      DNI
                                    </th>
                                  </tr>
                                  <tr>
                                    <td className="text-center text-left">
                                      <CompleteName name={Datos.name} surname={Datos.surname} />
                                    </td>
                                    <td className="text-center text-left">
                                      {Datos.dni_str ? Datos.dni_str : Datos.dni}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="text-center text-left">
                                      Telefono
                                    </th>
                                    <th className="text-center text-left">
                                      Gmail
                                    </th>
                                  </tr>
                                  <tr>
                                    <td className="text-center text-left">
                                      {Datos.phone_number}
                                    </td>
                                    <td className="text-center text-left">
                                      {Datos.mail}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <hr></hr>
                              <p className="text-center text-left text-40">
                                {" "}
                                lat: {Datos.lat} lng: {Datos.lng}{" "}
                              </p>
                              <div className="d-flex justify-content-center">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td colSpan={2}>
                                        <label>Descripción:</label>
                                        <input
                                          className="form-control"
                                          name="description"
                                          id="description"
                                          type="text"
                                          onChange={this.handleChange}
                                          value={this.state.description}
                                        ></input>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <button
                                          className="btn btn-dark btn-sm"
                                          onClick={() =>
                                            this.CamabiarEstado(
                                              Datos.history_id.id
                                                ? Datos.history_id.id
                                                : Datos.history_id,
                                              document.getElementById("estado")
                                                .value
                                            )
                                          }
                                        >
                                          Cambiar
                                        </button>
                                      </td>

                                      <td>
                                        <select
                                          className="form-select mb-3"
                                          id="estado"
                                          name="estado"
                                          defaultValue="Estado"
                                        >
                                          {estados.map((estado) => (
                                            <option
                                              key={estado.value}
                                              value={estado.value}
                                            >
                                              {estado.label}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Popup>
                          </Marker>
                        ) : null}
                      </div>
                    ))}

                    {sosLocalAlerts.map(data => <LocalAlert key={data.id} data={data} />)}

                    {MarkersTraccar.map((device) => <DeviceMarkers key={device.id} data={device} />)}
                  </MapContainer>
                </div>
                <div className="text-center">
                  <FullscreenIcon
                    className="pointer border"
                    style={{ fontSize: "40px" }}
                    onClick={this.handleToggleFullscreen}
                  />
                </div>
              </div>
            </div>
            <AlertNoGps 
              data={this.state.Markers_ws} 
              handleChange={this.handleChange}
              cambiarEstado={this.cambiarEstado}
              state={this.state}
              />
          </div>
        </div>
      </div>
    );
  }
}

export default Mapa;
