const estados = [
    { value: '1', label: 'Denunciado', color: 'danger' },
    { value: '2', label: 'Verificado', color: 'primary' },
    { value: '3', label: 'Resuelto', color: 'success' },
    { value: '0', label: 'Cerrado', color: 'secondary' },
];

export const estadosSOS = [
    { value: '0', label: 'Inactivo' },
    { value: '1', label: 'Activo' },
];

export default estados;
