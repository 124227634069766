import React from "react";
import Menu from "../../../Common/Menu"
import Nav from "../Nav"
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import { getApiUrl } from "../../../Common/api";


class TipoDenuncias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ListTiposDenuncia: [],
        };
    }

    CargarTiposDenuncia() {
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/typecomplaintadm/", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    ListTiposDenuncia: result,
                })
            })
            .catch(error => console.log('error', error));
    }

    AgregarTipo() {
        Swal.fire({
            title: 'Agregar nuevo tipo de denuncia',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Agregar',
            showLoaderOnConfirm: true,
            backdrop: true,
            preConfirm: (text) => {
                if (text === null || text === '') {
                    Swal.showValidationMessage('Por favor, introduce un valor');
                } else {
                    return Promise.resolve(text);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                var access_token = localStorage.getItem("access_token");
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "name": result.value
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                const api = getApiUrl();
                fetch(api + "alert/typecomplaintadm/", requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Your work has been saved',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        this.CargarTiposDenuncia()
                    })
                    .catch(error => console.log('error', error));


            }
        })
    }

    EditarTipoDenuncia(id) {
        Swal.fire({
            title: 'Editar tipo de denuncia',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Editar',
            showLoaderOnConfirm: true,
            backdrop: true,
            preConfirm: (text) => {
                if (text === null || text === '') {
                    Swal.showValidationMessage('Por favor, introduce un valor');
                } else {
                    return Promise.resolve(text);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                var access_token = localStorage.getItem("access_token");
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "name": result.value
                });

                var requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                const api = getApiUrl();
                fetch(api + "alert/typecomplaintadm/" + id + "/", requestOptions)
                    .then(response => response.text())
                    .then(Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Your work has been saved',
                        showConfirmButton: false,
                        timer: 1500
                    }))
                    .catch(error => console.log('error', error));
                this.CargarTiposDenuncia()
            }
        })
    }

    componentDidMount() {
        this.CargarTiposDenuncia()
    }

    render() {
        const { ListTiposDenuncia } = this.state;
        return (
            <div id="tipoDenuncias">
                <Menu />
                <div className="row">
                    <Nav />
                    <div className="col-12 col-md-10 border d-flex flex-column justify-content-center">
                        <div className="border my-1 p-1">
                            <Link className="btn btn-link rounded-0 border border-dark shadow-none my-2 d-flex justify-content-center align-items-center Link" to="/Alertas/Denuncias">
                                <KeyboardBackspaceIcon />
                                <span className="ml-2 d-none d-md-block">Regresar</span>
                            </Link>
                            <Link onClick={() => this.AgregarTipo()} className="Link d-flex align-items-center text-black">
                                <AddIcon />
                                <h5 className="mb-0 ms-2" >Añadir</h5>
                            </Link>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Tipo de denuncia</th>
                                            <th>Editar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ListTiposDenuncia.map((List) => (
                                            <tr key={List.id}>
                                                <td>{List.name}</td>
                                                <td>
                                                    <button onClick={() => this.EditarTipoDenuncia(List.id)} className="btn btn-primary">Editar</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TipoDenuncias;