import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { botonSegunEstado, FormatoHora } from '../../../../Common/Funciones';
import SearchIcon from '@mui/icons-material/Search';
import { CompleteName } from '../../../../Common/componentes/CompleteName';
import { getApiUrl } from '../../../../Common/api';

function AlertSosCard({ detalle }) {
    return (
        <Link key={detalle.id} className="col-md-6 mb-3 Link" to={`/Alertas/Sos/${detalle.id}`}>
            <div className="border rounded p-3">
                <p>
                    Nombre: <CompleteName name={detalle.more_info_person.name} surname={detalle.more_info_person.surname} />
                </p>
                <p>
                    Dni: {detalle.more_info_person.dni_str}
                </p>
                <p>
                    Teléfono: {detalle.more_info_person.phone_number}
                </p>
                <p>
                    Descripción: {detalle.description}
                </p>
                <p>
                    Creado: {FormatoHora(detalle.date_time_created)} Finalizado: {FormatoHora(detalle.date_time_terminated)}
                </p>
                <p>
                    {botonSegunEstado(detalle.status)}
                </p>
            </div>
        </Link>
    )
}

export function AlertSosList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        cargarDetalle();
    }, []);

    const cargarDetalle = () => {
        setLoading(true)
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/historypersonsos/?person_id=1", requestOptions)
            .then(response => response.json())
            .then(async (result) => {
                const filteredResult = result.filter(item => item.lat !== 'null' && item.lng !== 'null' && item.lat !== null && item.lng !== null);
                setData(result)
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false))
    }

    const buscarDetalle = (dni) => {
        setLoading(true)
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/historypersonsos/?person_id__dni=" + dni, requestOptions)
            .then(response => response.json())
            .then(result => {
                const filteredResult = result.filter(item => item.lat !== 'null' && item.lng !== 'null' && item.lat !== null && item.lng !== null);
                setData(result)
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false))
    }

    if (loading) return <>Cargando...</>
    
    return (
        <>
            <div className="input-group mb-3">
                <input id="search-input" type="text" className="form-control" placeholder="Buscar por dni..." />
                <div onClick={() => buscarDetalle(document.getElementById('search-input').value)} className="input-group-append pointer">
                    <span className="input-group-text"><SearchIcon /></span>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 ">
                {data.map((d) => (
                    <AlertSosCard key={d.id} detalle={d} />
                ))}
            </div>
        </>
    )
}
