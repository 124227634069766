import { ArticleSharp } from "@mui/icons-material";
import { useModal } from "../../../Common/hooks/useModal";
import { SimpleModal } from "../../../Common/componentes/SimpleModal";
import moment from "moment-timezone";
import { useState } from "react";
import { getReportHistorySos } from "../Denuncia/api/getReportHistorySos";
import toast from "react-hot-toast";
import { formatedDate, resolveStatusSOS } from "../../../Common/Reportes/Utils";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const SosReportContent = ({ generateReport }) => {
    const [dni, setDni] = useState('');
    const [fechaInicio, setFechaInicio] = useState(moment().format('YYYY-MM-DD'));
    const [fechaFin, setFechaFin] = useState(moment().format('YYYY-MM-DD'));

    return (
        <div>
            <div className="d-flex align-items-center mb-2">
                <div className="col-4">Fecha Inicio</div>
                <div className="col-8">
                    <input type="date" className="form-control" value={fechaInicio} onChange={(e) => setFechaInicio(e.target.value)} />
                </div>
            </div>
            <div className="d-flex align-items-center mb-2">
                <div className="col-4">Fecha Fin</div>
                <div className="col-8">
                    <input type="date" className="form-control" value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} />
                </div>
            </div>

            <div className="d-flex align-items-center mb-2">
                <div className="col-4">DNI</div>
                <div className="col-8">
                    <input type="text" className="form-control" value={dni} onChange={(e) => setDni(e.target.value)} />
                </div>
            </div>

            <div className="d-flex align-items-center">
                <div className="col-4"></div>
                <div className="col-9">
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => generateReport(fechaInicio, fechaFin, dni)}
                    >
                        Generar Reporte
                    </button>
                </div>
            </div>
        </div>
    )
}

export function SosReport() {
    const { isOpen, openModal, closeModal } = useModal()

    const generateReportFile = async (start_date, end_date, dni) => {
        try {
            const res = await getReportHistorySos(start_date, end_date, dni)
            if (!res.ok) {
                throw new Error("Error al obtener los datos");
            }
            const data = await res.json()
            if (data.length === 0) {
                toast.error("No se encontró data para generar el reporte");
                throw new Error();
            }
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Reporte');
            worksheet.columns = [
                { header: 'ID', key: 'id', width: 10 },
                { header: 'Nombre', key: 'name', width: 30 },
                { header: 'Apellido', key: 'surname', width: 30 },
                { header: 'DNI', key: 'dni_str', width: 10 },
                { header: 'Teléfono', key: 'phone_number', width: 10 },
                { header: 'Descripción', key: 'description', width: 40 },
                { header: 'Latitud', key: 'lat', width: 20 },
                { header: 'Longitud', key: 'lng', width: 20 },
                { header: 'Fecha Creación', key: 'date_time_created', width: 23 },
                { header: 'Fecha Cierre', key: 'date_time_terminated', width: 23 },
                { header: 'Estado', key: 'status', width: 20 }
            ];

            worksheet.getRow(1).eachCell((cell) => {
                cell.font = { bold: true };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFCCFFCC' }
                };
            });

            data.forEach((item) => {
                worksheet.addRow({
                    id: item.id,
                    name: item.more_info_person?.name || 'N/A',
                    surname: item.more_info_person?.surname || 'N/A',
                    dni_str: item.more_info_person?.dni_str || 'N/A',
                    phone_number: item.more_info_person?.phone_number || 'N/A',
                    type_name: item.type_name || 'N/A',
                    description: item.description || 'N/A',
                    lat: item.lat === "null" ? 'N/A' : item.lat,
                    lng: item.lng === "null" ? 'N/A' : item.lng,
                    date_time_created: formatedDate(item.date_time_created),
                    date_time_terminated: formatedDate(item.date_time_terminated),
                    status: resolveStatusSOS(item.status),
                });
            });
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(blob, `Reporte_SOS_${start_date}_${end_date}.xlsx`);
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const generateReport = (fechaInicio, fechaFin, dni) => {
        closeModal()
        toast.promise(generateReportFile(fechaInicio, fechaFin, dni), {
            loading: 'Generando Reporte',
            success: 'Reporte generado con éxito',
            error: 'Error al generar reporte'
        })
    }

    return (
        <div className="">
            <div
                className="btn btn-outline-dark btn-sm d-flex align-items-center"
                onClick={openModal}
            >
                <ArticleSharp fontSize="large" />
                <div style={{ fontSize: "1.1rem" }}>REPORTE</div>
            </div>
            <SimpleModal isOpen={isOpen} onClose={closeModal} title={"Generar Reporte SOS"}>
                <SosReportContent generateReport={generateReport} />
            </SimpleModal>
        </div>
    )
}
