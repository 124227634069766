import { getApiUrl } from "../../../../Common/api";

export const getReportComplain = (start_date, end_date, idType) => {
    const access_token = localStorage.getItem("access_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders
    };
    const api = getApiUrl();
    const queryParms = `?start_date=${start_date}&end_date=${end_date}&type=${idType}`
    return fetch(api + "/alert/reportcomplaint/" + queryParms, requestOptions);
}
