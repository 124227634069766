import { getApiUrl } from "../../../../Common/api";

export const getTipoDenuncias = () => {
    const access_token = localStorage.getItem("access_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    const api = getApiUrl();
    return fetch(api + "alert/typecomplaintadm/", requestOptions);
}
