import React from "react";
import Carga from "../Common/Carga";
import UserHome from "./UserHome";
import UserLogin from "./UserLogin";
import { apiBussiness } from "../../Common/Funciones";
import Swal from "sweetalert2";
import { getConnectionBussines } from "../../Common/utils/storageUtils";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sesion: null,
      carga: null,
      code: "NWiJDxygZXbdxWi",
      code_status: null,
    };
    this.CargarPagina = this.CargarPagina.bind(this);
  }

  CargarPagina() {
    let access_token = localStorage.getItem("access_token");
    let refresh_token = localStorage.getItem("refresh_token");

    if (
      access_token &&
      refresh_token &&
      access_token !== "" &&
      refresh_token !== ""
    ) {
      this.setState({
        sesion: 1,
      });
    } else {
      this.setState({
        sesion: null,
      });
    }
  }
  CargarApis(code) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    var raw = '{\r\n    "hash_code":"' + code + '"\r\n}\r\n';

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiBussiness, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data === null) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 3000,
          });
          return false;
        } else {
          this.setState({
            code_status: 1,
          });
          //sessionStorage.setItem("conexioneBussines", JSON.stringify(result));
          localStorage.setItem("conexioneBussines", JSON.stringify(result));
          return true;
        }
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: error,
          showConfirmButton: false,
          timer: 3000,
        });
        return false;
      });
  }

  componentDidMount() {
    // let conexioneBussines = sessionStorage.getItem("conexioneBussines");
    let conexioneBussines = getConnectionBussines();
    if (conexioneBussines) {
      this.setState({
        code_status: 1,
      });
    } else {
      this.setState({
        code_status: null,
      });
      this.pedirCode();
    }
    this.CargarPagina();
    setTimeout(() => {
      this.setState({
        carga: 1,
      });
    }, 300);
  }
  pedirCode() {
    
    Swal.fire({
      title: "Escriba su codigo de usuario",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: false,
      confirmButtonText: "Ya esta",
      showLoaderOnConfirm: true,
      cancelButtonText: "No lo tengo",
      preConfirm: async (login) => {
        let respuesta_codigo = this.CargarApis(login);
        return { respuesta_codigo, login };
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.respuesta_codigo === true) {
          Swal.close();
        } else {
          Swal.showValidationMessage("Código incorrecto");
        }
      }
    });
  }

  render() {
    return (
      <div>
        {this.state.carga ? "" : <Carga />}
        {this.state.code_status ? (
          this.state.sesion ? (
            <UserHome />
          ) : (
            <UserLogin cargarPagina={this.CargarPagina} />
          )
        ) : (
          <div>
            <i>Necesitas el codigo de acceso, puedes pedir este codigo a NW.</i>
            <br></br>
            <button onClick={() => this.pedirCode()} className="btn btn-info">
              REINTENTAR
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default User;
