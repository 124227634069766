import { getConnectionBussines } from "./utils/storageUtils";

export const apiBussiness = "http://147.182.180.146:7766/bussiness/api/info/";
export function botonSegunEstado(estado) {
  let label;
  let clase;
  let disabled;

  switch (estado) {
    case 0:
      label = "INACTIVO";
      clase = "btn btn-danger";
      disabled = true;
      break;
    case 1:
      label = "ACTIVO";
      clase = "btn btn-success";
      disabled = true;
      break;
    case 2:
      label = "INSPECCIONANDO";
      clase = "btn btn-primary";
      disabled = true;
      break;
    default:
      label = "NO ENCONTRADO";
      clase = "btn btn-danger";
      disabled = true;
  }

  return (
    <button className={clase} disabled={disabled}>
      {label}
    </button>
  );
}

export function FormatoHora(hora) {
  if (hora === null) {
    return "Sin terminar";
  }

  let date = new Date(hora);
  let horaPeruana = date.toLocaleTimeString("es-PE", { timeZone: "America/Lima" });
  let fechaPeruana = date.toLocaleDateString("es-PE", { timeZone: "America/Lima" });

  return (
    <span>
      {horaPeruana} {fechaPeruana}
    </span>
  );
}

export function LogoPage() {
  let logo_img = "./img/logo-muni.png";
  let conexioneBussines = getConnectionBussines();
  if (conexioneBussines) {
    let dataJSON = JSON.parse(conexioneBussines);
    logo_img  = dataJSON.data.image_b64;
  }
  return logo_img;
}
