import React from "react";
import Menu from "../../../Common/Menu";
import Nav from "../Nav";
import { Link, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import estados from '../../../Common/Estados';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FormatoHora } from "../../../Common/Funciones";
import { getPersonInfo } from "../../../services/AlertSosService";
import { CompleteName } from "../../../Common/componentes/CompleteName";
import { ButtonDenunciasSegunEstado } from "../../../Common/componentes/ButtonDenunciasSegunEstado";
import toast from "react-hot-toast";
import { getApiUrl } from "../../../Common/api";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />;
    };
}

class DetalleAlertaDenuncias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Detalle: [],
            currentImageIndex: 0,
            loadingDetalle: true
        };
    }

    async getPersonInfoDetalle(result) {
        try {
            const detail = await getPersonInfo(result.person_id, null);
            result.detailPerson = detail;
            this.setState({
                Detalle: result
            })
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({
                loadingDetalle: false,
            })
        }
    }

    CargarDetalle() {
        const IdParam = this.props.match.params.id;
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/complaintperson/" + IdParam + "/", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.getPersonInfoDetalle(result)
            })
            .catch(error => console.log('error', error));
    }

    changeImage = (index) => {
        this.setState({ currentImageIndex: index });
    };

    CamabiarEstado(id, value, dataExtra) {
        if (value === "") return;
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + access_token);

        var raw = JSON.stringify({
            "status": value,
            "description": "Sos valido",
            "data_extra": dataExtra
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/complaintperson/" + id + "/", requestOptions)
            .then(async (response) => {
                if (response.status === 400) {
                    const msg = await response.json();
                    toast.error(msg.message, { position: "top-center" })
                }
                if (response.status === 200) {
                    this.CargarDetalle();
                }
            })
            .catch(error => console.log('error', error));
    }

    componentDidMount() {
        this.CargarDetalle();
    }

    render() {
        const icon = L.icon({
            iconUrl: "./img/Icon-primary.png",
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
        });
        return (
            <div id="detalleAlertaDenuncias">
                <Menu />
                <div className="row">
                    <Nav />
                    <div className="col flex-grow-1">
                        <div className="container-fluid d-flex flex-column h-100">
                            <div className="border my-1 p-1">
                                <Link
                                    className="btn btn-link rounded-0 border border-dark shadow-none my-2 d-flex justify-content-center align-items-center Link"
                                    to="/Alertas/Denuncias"
                                >
                                    <KeyboardBackspaceIcon />
                                    <span className="ml-2 d-none d-md-block">Regresar</span>
                                </Link>
                                <div className="row flex-grow-1">
                                    <div className="col border d-flex flex-column justify-content-center">
                                        <div className="mb-3">
                                            <h1 className="mb-3">
                                                {this.state.Detalle.type_name ? this.state.Detalle.type_name : "Sin Titulo"}
                                            </h1>
                                            <p className="mb-0 text-muted">Nombre: {this.state.Detalle.detailPerson ?
                                                <CompleteName name={this.state.Detalle.detailPerson.name} surname={this.state.Detalle.detailPerson.surname} /> : "Sin Nombre"}</p>

                                            <p className="mb-0 text-muted">Dni: {this.state.Detalle.detailPerson ? this.state.Detalle.detailPerson.dni : "Sin Dni"}</p>
                                            <p className="mb-0 text-muted">Telefono: {this.state.Detalle.detailPerson ? this.state.Detalle.detailPerson.phone_number : "Sin Telefono"}</p>
                                            <p className="mb-0 text-muted">
                                                {this.state.Detalle.data_informer ? this.state.Detalle.data_informer : "Sin descripción"}
                                            </p>
                                            <p className="mb-0 text-muted">
                                                Creado: {FormatoHora(this.state.Detalle.date_time_created)}
                                            </p>
                                            <p className="mb-0 text-muted">
                                                Finalizado:{" "}
                                                {FormatoHora(this.state.Detalle.date_time_terminated) ? FormatoHora(this.state.Detalle.date_time_terminated) : "Sin Terminar"}
                                            </p>
                                            <ButtonDenunciasSegunEstado estado={this.state.Detalle.status} />
                                        </div>

                                        <div className="mb-3 border-top pt-2">
                                            <div className="mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="data-extra-denuncia"
                                                    placeholder="Más información"
                                                    defaultValue={this.state.Detalle.data_extra ?? ""}
                                                />
                                            </div>
                                            <select className="custom-select mr-sm-2 mb-2" id="estado" name="estado" defaultValue="">
                                                <option value="">---Seleccione Estado---</option>
                                                {estados.map((estado) => (
                                                    <option key={estado.value} value={estado.value}>{estado.label}</option>
                                                ))}
                                            </select>
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    className="btn btn-dark btn-sm"
                                                    onClick={() =>
                                                        this.CamabiarEstado(
                                                            this.state.Detalle.id,
                                                            document.getElementById('estado').value,
                                                            document.getElementById('data-extra-denuncia').value
                                                        )}
                                                >
                                                    Cambiar
                                                </button>
                                            </div>
                                        </div>

                                        {this.state.Detalle?.photos_compliment && (
                                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                                <ol className="carousel-indicators">
                                                    {this.state.Detalle.photos_compliment.map((photo, index) => (
                                                        <li
                                                            key={index}
                                                            data-target="#carouselExampleIndicators"
                                                            data-slide-to={index}
                                                            className={index === this.state.currentImageIndex ? "active" : ""}
                                                        ></li>
                                                    ))}
                                                </ol>
                                                <div className="carousel-inner">
                                                    {this.state.Detalle.photos_compliment.map((photo, index) => (
                                                        <div
                                                            key={`photo_${index}`}
                                                            className={`carousel-item ${index === this.state.currentImageIndex ? "active" : ""}`}
                                                        >
                                                            <img
                                                                className="d-block w-100"
                                                                src={photo.url}
                                                                alt={`Slide ${index}`}
                                                                onClick={() => this.changeImage(index)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <button
                                                    className="carousel-control-prev"
                                                    type="button"
                                                    data-target="#carouselExampleIndicators"
                                                    data-slide="prev"
                                                    onClick={() => this.changeImage((this.state.currentImageIndex - 1 + this.state.Detalle.photos_compliment.length) % this.state.Detalle.photos_compliment.length)}
                                                >
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="sr-only">Previous</span>
                                                </button>
                                                <button
                                                    className="carousel-control-next"
                                                    type="button"
                                                    data-target="#carouselExampleIndicators"
                                                    data-slide="next"
                                                    onClick={() => this.changeImage((this.state.currentImageIndex + 1) % this.state.Detalle.photos_compliment.length)}
                                                >
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="sr-only">Next</span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col">
                                        <div className="border map w-100">
                                            {
                                                this.state.loadingDetalle ? (
                                                    <>Cargando ...</>
                                                ) :
                                                    (
                                                        <MapContainer
                                                            center={[this.state.Detalle.lat || -9.9308, this.state.Detalle.lng || -76.2421]} zoom={14}
                                                            scrollWheelZoom={true}>
                                                            <TileLayer
                                                                attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                            />
                                                            <Marker
                                                                position={[this.state.Detalle.lat || -9.9308, this.state.Detalle.lng || -76.2421]}
                                                                icon={icon}>
                                                                <Popup>
                                                                    <p>lat: {this.state.Detalle.lat || "Sin datos "} lng: {this.state.Detalle.lng || "Sin datos"}</p>
                                                                </Popup>
                                                            </Marker>
                                                        </MapContainer>
                                                    )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DetalleAlertaDenuncias);
