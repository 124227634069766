import { Link } from "react-router-dom"
import { FormatoHora } from "../../../../Common/Funciones"
import { CompleteName } from "../../../../Common/componentes/CompleteName";
import { ButtonDenunciasSegunEstado } from "../../../../Common/componentes/ButtonDenunciasSegunEstado";

export function DenunciasCard({ denuncia }) {
    return (
        <Link key={denuncia.id} className="col-md-6 mb-3 Link" to={`/Alertas/Denuncias/${denuncia.id}`}>
            <div className="border rounded p-3">
                <p className="font-weight-bold">
                    {denuncia.type_name}
                </p>
                <p>
                    Nombre: <CompleteName name={denuncia.more_info_person.name} surname={denuncia.more_info_person.surname} />
                </p>
                <p>
                    Dni: {denuncia.more_info_person.dni_str}
                </p>
                <p>
                    Teléfono: {denuncia.more_info_person.phone_number}
                </p>
                <p>
                    Descripción: {denuncia.data_informer}
                </p>
                <p>
                    Creado: {FormatoHora(denuncia.date_time_created)}
                </p>
                <p>
                    Finalizado: {FormatoHora(denuncia.date_time_terminated)}
                </p>
                <p>
                    <ButtonDenunciasSegunEstado estado={denuncia.status} />
                </p>
            </div>
        </Link>
    )
}
