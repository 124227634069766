import { getApiUrl } from "../Common/api";

export const getPersonInfo = async (userId, signal) => {
    // var access_token = localStorage.getItem("access_token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", "Bearer " + access_token);
    const requestOptions = {
        headers: myHeaders,
        signal: signal,
    }
    const api = getApiUrl()
    const res = await fetch(api + "api/person/" + userId + "/", requestOptions);
    const json_data = await res.json();
    return json_data;
};
