export function CompleteName({ name, surname }) {
    if (!name || !surname) {
        return "Cargando..."
    }
    return (
        <>
            {name} {" "} {surname}
        </>
    )
}
