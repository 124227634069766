import moment from "moment-timezone";
import estados, { estadosSOS } from "../Estados";

export const resolveImages = (images) => {
    // receive images: list of dicts
    if (images.length === 0) {
        return 'N/A';
    }
    return images.map((image) => image.url).join(',');
}

export const formatedDate = (date) => {
    if (!date) {
        return 'N/A';
    }
    return moment(date).format('DD/MM/YYYY hh:mm:ss a');
}

export const resolveStatus = (status) => {
    if (status === null || status === 'null') {
        return 'N/A';
    }
    const estado = estados.find((e) => e.value === String(status));
    return estado ? estado.label : '';
}

export const resolveStatusSOS = (status) => {
    if (status === null || status === 'null') {
        return 'N/A';
    }
    const estado = estadosSOS .find((e) => e.value === String(status));
    return estado ? estado.label : '';
}
