import { botonSegunEstado } from "../../../Common/Funciones";
import estados from "../../../Common/Estados";
import { CompleteName } from "../../../Common/componentes/CompleteName";
import { SimpleModal } from "../../../Common/componentes/SimpleModal";
import { useState } from "react";


export const AlertNoGps = ({ data, handleChange, cambiarEstado, state }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const newData = data.filter(d => d.lat === "null" || d.lng === "null")
    return (
        <div className="mt-5">
            <h4 className="alert alert-danger text-center">Alertas sin GPS</h4>
            <table className="table">
                <thead>
                    <tr>
                        <th className="text-center text-left">
                            Nombres
                        </th>
                        <th className="text-center text-left">
                            DNI
                        </th>
                        <th className="text-center text-left">
                            Telefono
                        </th>
                        <th className="text-center text-left">
                            Estado
                        </th>
                        <th className="text-center text-left">
                            Fecha de Creación
                        </th>
                        <th className="text-center text-left">
                            Ver
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        newData.length === 0 && (
                            <tr>
                                <td colSpan={6} className="text-center">
                                    No hay alertas sin GPS registradas
                                </td>
                            </tr>
                        )
                    }
                    {
                        newData.map(d => (
                            <tr key={d.date_time_update_coords}>
                                <td className="text-center text-left">
                                    <CompleteName name={d.name} surname={d.surname} />
                                </td>
                                <td className="text-center text-left">
                                    {d.dni}
                                </td>
                                <td className="text-center text-left">
                                    {d.phone_number}
                                </td>
                                <td>
                                    <p className="m-0 mb-2">{botonSegunEstado(d.status)}</p>
                                </td>
                                <td className="text-center text-left">
                                    <p className="m-0">{d.alert_date_created}</p>
                                </td>
                                <td className="text-center text-left">
                                    <button className="btn btn-primary" onClick={() => setIsModalOpen(true)}>ver</button>
                                    <SimpleModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title={"Detalle"}>
                                        <div>
                                            <p className="text-center text-left">
                                                {" "}
                                                {botonSegunEstado(d.status)} Creado:{" "}
                                                {d.alert_date_created}
                                            </p>
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th className="text-center text-left">
                                                            Nombre
                                                        </th>
                                                        <th className="text-center text-left">
                                                            DNI
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center text-left">
                                                            <CompleteName name={d.name} surname={d.surname} />
                                                        </td>
                                                        <td className="text-center text-left">
                                                            {d.dni_str ? d.dni_str : d.dni}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-center text-left">
                                                            Telefono
                                                        </th>
                                                        <th className="text-center text-left">
                                                            Gmail
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center text-left">
                                                            {d.phone_number}
                                                        </td>
                                                        <td className="text-center text-left">
                                                            {d.mail}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <hr></hr>
                                            <p className="text-center text-left text-40">
                                                {" "}
                                                lat: {d.lat === "null" ? "Sin latitud" : d.lat} lng: {d.lng === "null" ? "Sin longitud" : d.lng}{" "}
                                            </p>
                                            <div className="d-flex justify-content-center">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <label>Descripción:</label>
                                                                <input
                                                                    className="form-control"
                                                                    name="description"
                                                                    id="description"
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    value={state.description}
                                                                ></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <button
                                                                    className="btn btn-dark btn-sm"
                                                                    onClick={() =>
                                                                        cambiarEstado(
                                                                            d.history_id.id
                                                                                ? d.history_id.id
                                                                                : d.history_id,
                                                                            document.getElementById("estado")
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    Cambiar
                                                                </button>
                                                            </td>

                                                            <td>
                                                                <select
                                                                    className="form-select mb-3"
                                                                    id="estado"
                                                                    name="estado"
                                                                    defaultValue="Estado"
                                                                >
                                                                    {estados.map((estado) => (
                                                                        <option
                                                                            key={estado.value}
                                                                            value={estado.value}
                                                                        >
                                                                            {estado.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </SimpleModal>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
