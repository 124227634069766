import React from "react";
import Menu from "../../../Common/Menu"
import Nav from "../Nav"
import SosIcon from '@mui/icons-material/Sos';
import { AlertSosList } from "./components/AlertSosList";
import { SosReport } from "../Reportes/SosReport";

function AlertaSOS() {
    return (
        <div id="alertaSOS">
            <Menu />
            <div className="row">
                <Nav />
                <div className="col-12 col-md-10 border d-flex flex-column justify-content-center align-items-center">
                    <div className="row my-2">
                        <div className="col-md-12 mb-3">
                            <div className="d-flex align-items-center">
                                <SosIcon style={{ fontSize: "2rem" }} />
                                <h1 className="ml-2 mb-0" style={{ fontSize: "2rem" }}>Alerta SOS</h1>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <SosReport />
                    </div>

                    <AlertSosList />
                </div>
            </div>
        </div>
    );
}

export default AlertaSOS;
