import React, { useEffect } from 'react';

export function SimpleModal({ isOpen, onClose, children, title }) {
    useEffect(() => {
        // Cleanup
        //console.log("destroy")
        return () => {
            onClose();
        };
    }, []);

    if (!isOpen) return null;

    return (
        <div className="modal d-block" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}