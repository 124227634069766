import React from "react";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupsIcon from "@mui/icons-material/Groups";
import Menu from "../../Common/Menu";
import { Link } from "react-router-dom";
import { getConnectionBussines } from "../../Common/utils/storageUtils";
import { getApiUrl } from "../../Common/api";
class UserHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policas: null,
      urlVideo: ""
    };
  }

  validar() {
    const menu = new Menu();
    var access_token = localStorage.getItem("access_token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const api = getApiUrl();
    fetch(api + "auth/info/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.code) {
          menu.Cerrar();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  componentDidMount() {
    this.validar();
    const bussinesData = JSON.parse(getConnectionBussines());
    if (bussinesData) {
      this.setState({
        urlVideo: bussinesData.data.url_video || "https://www.youtube.com/embed/8W4-98yCHow?autoplay=1&mute=1"
      });
    }
    document.body.style.backgroundColor = "#fff";
  }

  render() {
    return (
      <div className="home">
        <Menu />
        <div className="container">
          <div className="row">
            <Link className="col-md-4 mt-3 pointer Link" to="/Alertas/Mapa">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-center align-items-stretch">
                  <div className="d-flex align-items-center mb-3 justify-content-center">
                    <AddAlertIcon />
                    <h5 className="card-title mb-0 ml-2 text-center">
                      ALERTAS
                    </h5>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              className="col-md-4 mt-3 Link inactive" /*to="/Predios-Tributo"*/
            >
              <div className="card h-100 pointer">
                <div className="card-body d-flex flex-column justify-content-center align-items-stretch">
                  <div className="d-flex align-items-center mb-3 justify-content-center">
                    <ReceiptLongIcon />
                    <h5 className="card-title mb-0 ml-2 text-center">
                      PREDIOS TRIBUTO
                    </h5>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              className="col-md-4 mt-3 Link inactive" /*to="/Planes-Sociales"*/
            >
              <div className="card h-100 pointer">
                <div className="card-body d-flex flex-column justify-content-center align-items-stretch">
                  <div className="d-flex align-items-center mb-3 justify-content-center">
                    <GroupsIcon />
                    <h5 className="card-title mb-0 ml-2 text-center">
                      PLANES SOCIALES
                    </h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="row justify-content-center my-4">
            <div className="col-10">
              <div className="embed-responsive embed-responsive-4by3">
                <iframe
                  className="embed-responsive-item w-100 mx-auto"
                  src={this.state.urlVideo}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  style={{ width: "100%", height: "75%" }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserHome;
