import { getApiUrl } from "../Common/api";

export const getHistoryInfo = async (histryId) => {
    var access_token = localStorage.getItem("access_token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + access_token);
    const requestOptions = {
        headers: myHeaders,
    }
    const api = getApiUrl()
    const res = await fetch(api + "alert/historypersonsos/" + histryId + "/", requestOptions);
    const json_data = await res.json();
    return json_data;
};
