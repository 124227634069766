import { getConnectionBussines } from "./utils/storageUtils";

function apiGet(){
    let apiTemp = "http://147.182.180.146:7777/";
    let conexioneBussines = getConnectionBussines();
    if (conexioneBussines) {
      let dataJSON = JSON.parse(conexioneBussines);
      apiTemp = "http://"+dataJSON.data.ip+":"+dataJSON.data.port+"/";
    }
    return apiTemp;
}

let api = apiGet();

export function getApiUrl() {
  return apiGet();
}

export default api;
