import React from "react";
import Menu from "../../../Common/Menu"
import Nav from "../Nav"
import { Link, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { estadosSOS } from '../../../Common/Estados';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { botonSegunEstado, FormatoHora } from "../../../Common/Funciones";
import { getPersonInfo } from "../../../services/AlertSosService";
import { CompleteName } from "../../../Common/componentes/CompleteName";
import { getApiUrl } from "../../../Common/api";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />;
    };
}

const isValidCoord = (latitude, longitude) => {
    const evaluationLat = !isNaN(latitude) && latitude != null && latitude != "null";
    const evaluationLng = !isNaN(longitude) && longitude != null && longitude != "null";
    return evaluationLat && evaluationLng;
}

class DetalleAlertaSOS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Detalle: null,
        };
    }

    async getPersonInfoDetalle(result) {
        const detail = await getPersonInfo(result.person_id, null);
        result.detailPerson = detail;
        this.setState({
            Detalle: result,
        })
    }

    CargarDetalle() {
        const IdParam = this.props.match.params.id;
        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/historypersonsos/" + IdParam + "/", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.getPersonInfoDetalle(result);
            })
            .catch(error => console.log('error', error));
    }

    CamabiarEstado(id, value) {

        var access_token = localStorage.getItem("access_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + access_token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "status": value,
            "description": "Sos valido"
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const api = getApiUrl();
        fetch(api + "alert/historypersonsos/" + id + "/", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        this.CargarDetalle();
    }

    componentDidMount() {
        this.CargarDetalle();
    }

    render() {
        const icon = L.icon({
            iconUrl: "./img/Icon-danger.png",
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
        });
        return (
            <div id="detalleAlertaSOS">
                <Menu />
                <div className="row">
                    <Nav />
                    <div className="col-12 col-md-10 border d-flex flex-column justify-content-center">
                        <div className="border my-1 p-1">
                            <Link className="btn btn-link rounded-0 border border-dark shadow-none my-2 d-flex justify-content-center align-items-center Link" to="/Alertas/Sos">
                                <KeyboardBackspaceIcon />
                                <span className="ml-2 d-none d-md-block">Regresar</span>
                            </Link>
                            {
                                this.state.Detalle ? (
                                    <>
                                        <div>
                                            <h1 className="mb-3">{this.state.Detalle.description ? this.state.Detalle.description : "Sin descripción"}</h1>
                                            <p className="mb-0 text-muted">Nombre: <CompleteName name={this.state.Detalle.detailPerson.name} surname={this.state.Detalle.detailPerson.surname} /> </p>
                                            <p className="mb-0 text-muted">Dni: {this.state.Detalle.detailPerson.dni}</p>
                                            <p className="mb-0 text-muted">Telefono: {this.state.Detalle.detailPerson.phone_number}</p>
                                            <p className="mb-0 text-muted">Creado: {FormatoHora(this.state.Detalle.date_time_created)}</p>
                                            <p className="mb-0 text-muted">Finalizado: {FormatoHora(this.state.Detalle.date_time_terminated) ? FormatoHora(this.state.Detalle.date_time_terminated) : "Sin Terminar"}</p>
                                            {botonSegunEstado(this.state.Detalle.status)}
                                        </div>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <button className="btn btn-dark btn-sm" onClick={() => this.CamabiarEstado(this.state.Detalle.id, document.getElementById('estado').value)}>Cambiar</button>
                                                    </td>
                                                    <td>
                                                        <select className="form-select mb-3" id="estado" name="estado" defaultValue="Estado">
                                                            {estadosSOS.map((estado) => (
                                                                <option key={estado.value} value={estado.value}>{estado.label}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                ) : (
                                    <p>Cargando...</p>
                                )
                            }

                        </div>
                        <div className="container-fluid">
                            <div className="border map order-first order-sm-last w-100">
                                {this.state.Detalle &&
                                    <MapContainer
                                        center={this.state.Detalle && isValidCoord(this.state.Detalle.lat, this.state.Detalle.lng) ? [this.state.Detalle.lat, this.state.Detalle.lng] : [-9.9308, -76.2421]}
                                        zoom={14}
                                        scrollWheelZoom={true}>
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />

                                        {this.state.Detalle && isValidCoord(this.state.Detalle.lat, this.state.Detalle.lng) && (
                                            <Marker position={[this.state.Detalle.lat, this.state.Detalle.lng]} icon={icon}>
                                                <Popup>
                                                    <p>lat: {this.state.Detalle.lat} lng: {this.state.Detalle.lng}</p>
                                                </Popup>
                                            </Marker>
                                        )}
                                    </MapContainer>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withRouter(DetalleAlertaSOS);