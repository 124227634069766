import { getApiUrl } from "../../../../Common/api";

export const getReportHistorySos = (start_date, end_date, dni) => {
    const access_token = localStorage.getItem("access_token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders
    };
    const queryParms = `?start_date=${start_date}&end_date=${end_date}&person_id__dni=${dni}`
    const api = getApiUrl();
    return fetch(api + "/alert/reporthistorysos/" + queryParms, requestOptions);
}
